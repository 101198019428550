

import React from "react";
import config from "../../../data/SiteConfig";


export default class FormSpree extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (

   
      <form
        onSubmit={this.submitForm}
        action={config.formSpreeUrl}
        method="POST"
      >

                <div className="pb-4">
                    <label htmlFor="full_name" className="sr-only">Full name</label>
                    <div className="relative rounded-md shadow-sm">
                    <input id="full_name" name="full_name" required className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Your full name" />
                    </div>
                </div>
                <div className="pb-4">
                    <label htmlFor="email" className="sr-only">Email</label>
                    <div className="relative rounded-md shadow-sm">
                    <input id="email" type="email" name="email" required className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Your email address" />
                    </div>
                </div>
                <div className="pb-4">
                    <label htmlFor="message" className="sr-only">Message</label>
                    <div className="relative rounded-md shadow-sm">
                    <textarea id="message" name="message" required rows="4" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Your message"></textarea>
                    <input type="hidden" name="form-name" value="contact" />
                    </div>
                </div>
                <div className="pb-4">
                    <span className="inline-flex rounded-md shadow-sm">
                    
                    <button type="submit" className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                        {config.contactSubmit}
                    </button>

                    </span>
                </div>
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
        window.location = "/contact-confirm";
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}