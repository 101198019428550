import React, { Component } from "react";
import config from "../../../data/SiteConfig";
import FormSpree from "../../components/ContactSections/FormSpree";

class SplitTwoTone extends Component {
  render() {
    return (
        <div className="relative bg-white">
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {config.contactTitle}
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                {config.contactSubTitle}
                </p>

            </div>
            </div>
            <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
                <FormSpree />

            </div>
            </div>
        </div>
        </div>
    );
  }
}

export default SplitTwoTone;
